import { gql } from '@apollo/client'
import { LoginTemplate } from 'components/template'
import { initializeApollo } from 'utils/apollo'
import { NextWrapper } from '../../data-fetching-wrapper'

function Login({ pagina }) {
  return <LoginTemplate pagina={pagina} />
}

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  const client = initializeApollo()

  const {
    data: { pagina },
  } = await client.query({
    query: gql`
      query {
        pagina(chiave: "login") {
          titolo
          breadcrumbs {
            titolo
            url
          }
          seo {
            title
            description
            image
          }
          chiave
        }
      }
    `,
  })

  if (!pagina) {
    return {
      notFound: true,
    }
  }

  return {
    props: { pagina },
    revalidate: 60 * 5,
  }
})

export default Login
